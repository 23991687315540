<template>
  <v-container fluid>
    <base-form />
  </v-container>
</template>

<script>


  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    data () {
      return {}
    },

    computed: {},

    watch: {
    },

    created () {
    },

    methods: {
    }
  }
</script>
